<template>
  <div class="main-block">
    <div class="main-block-title">Company info</div>
    <div class="main-block-content">

      <form @submit.prevent="submit">
        <div class="grid">
          <form-group label="Company Name" :value="form.name" :errors="errors.name">
            <v-select taggable
                      class="form-control"
                      label="title"
                      placeholder="Start typing your company name"
                      :reduce="option => option.title"
                      :options="this.companies"
                      :get-option-key="option => option.company_number"
                      v-model="form.name"
                      :select-on-key-codes="[9, 13]"
                      @search="search"
                      @option:selected="autocomplete"
            >
              <template #search="{ attributes, events }">
                <input
                    maxlength="35"
                    data-test="company_name_input"
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template v-slot:option="option">
                {{ option.title }}
                <span class="vs__number">{{ option.company_number }}</span>
              </template>
            </v-select>
          </form-group>

          <form-group label="Company Number" :value="form.number" :errors="errors.number">
            <input data-test="company_number" v-model="form.number" @change="validate(['number'])" type="text" class="form-control"/>
          </form-group>

          <form-group label="Registration Date" :value="form.registered_at" :errors="errors.registered_at">
            <input data-test="registration_date" v-model="form.registered_at" @change="validate(['registered_at'])" type="text" class="form-control" v-mask="['##.##.####']" placeholder="DD.MM.YYYY"/>
          </form-group>

          <form-group label="Website" :value="form.website" :errors="errors.website">
            <input data-test="website" v-model="form.website" @change="validate(['website'])" type="text" class="form-control" placeholder="https://"/>
          </form-group>
        </div>

        <file-upload @loading="setFileLoading" v-model="form.files" :types="companyFileTypes" @change="validate(['files'])" :errors="errors" />

        <div class="form-buttons text-right">
          <button type="submit" class="btn-next" data-test="submit" :disabled="filesLoading">
            <span>Save and proceed</span>
            <icon-next color="#19191C" size="15"/>
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import IconNext from "@/assets/icons/IconNext";
import FormGroup from "@/components/FormGroup";
import FileUpload from "@/components/FileUpload";
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Company',
  components: {
    FileUpload,
    FormGroup,
    IconNext
  },
  data () {
    return {
      form: {
        name: '',
        number: '',
        registered_at: '',
        website: '',
        files: []
      },

      errors: {},

      companySearchString: '',
      filesLoading: false
    }
  },
  computed: {
    ...mapGetters(['getRegistrationDataPart']),
    ...mapState({
      companyFileTypes: state => state.init.companyFileTypes,
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data
    }),
    formData () {
      return {
        name: this.form.name,
        number: this.form.number,
        registered_at: this.form.registered_at,
        website: this.form.website,
        files: this.form.files
      }
    }
  },
  asyncComputed: {
    companies: {
      get () {
        return this.$sdk.registration.getCompaniesList(this.companySearchString)
            .then(response => response.data)
      },
      default: []
    }
  },
  mounted () {
    const data = this.getRegistrationDataPart('company-business').attributes

    if (data) {
      this.form = data
    }
  },
  methods: {
    setFileLoading (value) {
      this.filesLoading = value
    },

    /**
     * Change search query string
     */
    search (search) {
      this.companySearchString = search
    },

    /**
     * Autocomplete
     */
    autocomplete (value) {
      let validatedAttributes = []

      if (typeof value === 'object') {
        if (value.title) {
          this.form.name = value.title
          validatedAttributes.push('name')
        }
        if (value.company_number) {
          this.form.number = value.company_number
          validatedAttributes.push('number')
        }
        if (value.registered_at) {
          this.form.registered_at = this.$moment(value.registered_at * 1000).format('DD.MM.YYYY')
          validatedAttributes.push('registered_at')
        }
      } else {
        this.form.name = value
        validatedAttributes.push('name')
      }

      this.validate(validatedAttributes)
    },

    /**
     * Form submit action
     */
    submit () {
      this.$sdk.registration.company.update(this.registrationHash, this.formData, 0).then(response => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            this.$emit('next-step')
          })
        } else {
          this.errors = response.data
        }
      })
    },

    /**
     * Fields validating
     * @param attributes
     */
    validate (attributes = []) {

      for (let name of ['files']) {
        let filesIndex = attributes.indexOf(name)
        if (filesIndex !== -1) {
          for (let i in this.formData[name]) {
            attributes.push(name + '[' + i + '][type]')
            attributes.push(name + '[' + i + '][name]')
            attributes.push(name + '[' + i + '][original_name]')
          }
        }
      }

      this.$sdk.registration.company.update(this.registrationHash, this.formData, 1).then(response => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    }
  }
}
</script>
